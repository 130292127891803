import { createContext, useContext, useState } from 'react'

const ToggleChatOpenStateContext = createContext(() => ({}))
const GetChatOpenStateContext = createContext(false)
const SetChatUndeadMessagesContext = createContext(() => ({}))
const GetChatUnreadMessagesContext = createContext(0)
const ChatStateContextProvider = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [unread, setUnread] = useState(0)

    const toggleChat = () => setIsOpen(!isOpen)
    return (
        <ToggleChatOpenStateContext.Provider value={toggleChat}>
            <GetChatOpenStateContext.Provider value={isOpen}>
                <SetChatUndeadMessagesContext.Provider value={setUnread}>
                    <GetChatUnreadMessagesContext.Provider value={unread}>
                        {children}
                    </GetChatUnreadMessagesContext.Provider>
                </SetChatUndeadMessagesContext.Provider>
            </GetChatOpenStateContext.Provider>
        </ToggleChatOpenStateContext.Provider>
    )
}

export const useToggleChat = () => {
    return useContext(ToggleChatOpenStateContext)
}

export const useChatOpenState = () => {
    return useContext(GetChatOpenStateContext)
}

export const useSetChatUnreadMessages = () => {
    return useContext(SetChatUndeadMessagesContext)
}

export const useChatUnreadMessages = () => {
    return useContext(GetChatUnreadMessagesContext)
}

export default ChatStateContextProvider


