import useSWR from 'swr'
import storage from '@utils/localStorage'
import checkLogin from '@utils/checkLogin'
const withUser = (TargetComponent) =>
    (props) => {
        const { data: user } = useSWR('user', storage)
        const isLoggedIn = checkLogin(user)

        return (
            <TargetComponent {...props}
                user={props.user || user}
                isLoggedIn={props.isLoggedIn || Boolean(props.user) || isLoggedIn} />
        )
    }

export default withUser